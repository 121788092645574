import React from 'react';
import { useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
import LoadingSpinner from '../components/UI/LoadingSpinner/LoadingSpinner';

const WithNoSession = (Component) => function HOC(props) {
  const uid = useSelector((state) => state.auth.userAuth.uid);
  const loaded = useSelector((state) => state.auth.loaded);
  const accountLoaded = useSelector((state) => state.account.loaded);
  const completed = useSelector((state) => state.onboarding.completed);
  // const redirect = useSelector((state) => state.main.redirect);
  const lightMode = useSelector((state) => state.main.lightMode);
  // const router = useRouter();

  // useEffect(() => {
  //   if (redirect) {
  //     if (typeof redirect === 'object') {
  //       const u = redirect.get('u');
  //       const a = redirect.get('a');
  //       if (u && a) {
  //         router.push(u, a);
  //       }
  //     } else {
  //       router.push(redirect);
  //     }
  //     getStore().dispatch(redirectAfterOnboarding(null));
  //   }
  // }, [redirect]);

  const renderPage = () => (
    <>
      <Component {...props} />
    </>
  );

  const renderLoader = () => (
    <div className="main-loader">
      <div className="main-loader-logo">
        <img src="/logo_dark.png" alt="Entre" />
      </div>
      <LoadingSpinner />
    </div>
  );

  if (loaded) {
    if (uid) {
      if (lightMode) {
        document.body.className = 'light';
      }
      if (accountLoaded) {
        if (completed) {
          if (typeof window !== 'undefined' && window.location.pathname === '/token-login') {
            return <meta httpEquiv="refresh" content="0; URL=/blog" />;
          }
          return <meta httpEquiv="refresh" content="0; URL=/feed" />;
        }
        return <meta httpEquiv="refresh" content="0; URL=/onboarding" />;
      }
      return renderLoader();
    }
    return renderPage();
  }

  return renderLoader();
};
export default WithNoSession;
