/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import AOS from 'aos';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadDeals } from '../../common/data/actions';
import 'aos/dist/aos.css';
import styles from './NewLanding.module.scss';
import DiamondIcon from '../../public/icons/svg/ExclusiveDeals.svg';
import ChatIcon from '../../public/icons/svg/landing-chat-icon.svg';
import GlobeIcon from '../../public/icons/svg/landing-globe-icon.svg';
import MarketIcon from '../../public/icons/svg/landing-market-icon.svg';
import CryptoIcon from '../../public/icons/svg/landing-page-crypto.svg';
import RoomsIcon from '../../public/icons/svg/landing-rooms-icon.svg';
import ArrowRightIcon from '../../public/icons/svg/arrow-right.svg';
import PlusIcon from '../../public/icons/svg/plus.svg';

const NewLanding = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeals());
    AOS.init({
      once: true,
    });
  }, []);

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const partners = [
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '200',
      style: { width: '106px', height: '19px' },
      src: '/images/landing/coinbase.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '200',
      style: { height: '14px', width: '117px' },
      src: '/images/landing/metamask.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '600',
      style: { height: '15px', width: '133px' },
      src: '/images/landing/WalletConnect.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '500',
      style: { height: '21px', width: '75px' },
      src: '/images/landing/deso.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '700',
      style: { height: '25px', width: '77px' },
      src: '/images/landing/ledger.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '800',
      style: { height: '23px', width: '86px' },
      src: '/images/landing/storj.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      delay: '400',
      style: { height: '19px', width: '171px' },
      src: '/images/landing/blockchain.png',
      alt: '',
    },
    {
      orientation: 'fade-right',
      duration: '1000',
      style: { height: '23px', width: '57px' },
      delay: '300',
      src: '/images/landing/stripe.png',
      alt: '',
    },
  ];

  const [active, setActive] = useState(null);

  const toggleActive = (id) => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
  };

  return (
    <>
      <div className={styles.main}>
        {/* start:partner */}
        <section className={styles.sectionOne}>
          <div className={styles.container}>
            <div className={styles.introTitle}>
              <h1>
                Welcome to the
                <span className={styles.titleGradient}>Future of Work</span>
                <span>.</span>
              </h1>
              <p className={styles.description}>
                Together, we’re redefining the meaning of work. Entre empowers individuals
                and companies to easily connect and work with the people and resources they
                need to succeed in the digital world.
              </p>
            </div>
            <div className={styles.partners}>
              {partners.map((partner) => (
                <img
                  key={partner.src}
                  data-aos={partner.orientation}
                  data-aos-duration={partner.duration}
                  data-aos-delay={partner.delay}
                  src={partner.src}
                  style={partner.style}
                  alt={partner.alt}
                  className={styles.image}
                />
              ))}
            </div>
          </div>
        </section>
        {/* end:partner */}

        {/* <section>
          <div className={styles.sectionTwo}>
            <div className={styles.container}>
              <h2>
                With Entre, you get instant access to
                {' '}
                <span className={styles.textGradient}>the most powerful networking platform</span>
                {' '}
                for web3 professionals.
              </h2>
              <button type="button" onClick={() => topFunction()} className={styles.arrowLink}>
                <p>Create Your Free Account</p>
                <div className={styles.arrowWrap}>
                  <ArrowRightIcon />
                </div>
              </button>
            </div>
          </div>
        </section> */}

        <section id="network" className={styles.sectionThree}>
          <div className={styles.container}>
            <div>
              <div className={styles.blockContainer} />
              <div className={styles.blockContainerRight}>
                <div
                  className={styles.containerContentRight}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <span className={styles.containerContentRight_title}>
                    Get instant access to the
                    <br />
                    <span className={styles.textGradient}>fastest growing network</span>
                    <br />
                    {' '}
                    of web3 professionals.
                  </span>
                  <ol className={styles.containerContentRight_list}>
                    <li>
                      <button type="button" onClick={() => toggleActive('01')}>
                        <div className={styles.divText}>
                          <span className={styles.spanText}>01</span>
                          Connect With Other Web3 Professionals
                        </div>
                        {' '}
                        <PlusIcon />
                        {' '}
                      </button>
                      {active === '01' ? (
                        <p>
                          Make meaningful connections with
                          like-minded people who share their insights
                          and experiences and help you progress towards your goals.
                        </p>
                      ) : null}
                    </li>
                    <div />
                    <li>
                      <button type="button" onClick={() => toggleActive('02')}>
                        <div className={styles.divText}>
                          <span className={styles.spanText}>02</span>
                          Monetize Your Content &amp; Expertise
                        </div>
                        {' '}
                        <PlusIcon />
                        {' '}
                      </button>
                      {active === '02' ? (
                        <p>
                          Our DeSo based platforms opens the door
                          for you to earn tangible income from
                          sharing your content and expertise on the Entre app.
                        </p>
                      ) : null}
                    </li>
                    <div />
                    <li>
                      <button type="button" onClick={() => toggleActive('03')}>
                        <div className={styles.divText}>
                          <span className={styles.spanText}>03</span>
                          Discover New Opportunities
                        </div>
                        {' '}
                        <PlusIcon />
                        {' '}
                      </button>
                      {active === '03' ? (
                        <p>
                          Our integrated marketplace makes
                          it easy for you to discover new opportunities
                          that will help you accelerate your career.
                        </p>
                      ) : null}
                    </li>
                    <div />
                  </ol>
                  <div className={styles.containerContentRight_button}>
                    <button type="button" onClick={() => topFunction()}>
                      Join for Free
                      <ArrowRightIcon />
                    </button>
                  </div>
                </div>
                <div className={styles.containerImage}>
                  <img
                    src="/images/landing-feature1.png"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    alt=""
                  />
                </div>
              </div>

              <div className={styles.blockContainer}>
                <div className={styles.containerImage}>
                  <img
                    src="/images/landing-feature2.png"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    alt=""
                  />
                </div>
                <div
                  className={styles.containerContentLeft}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <span className={styles.containerContentLeft_title}>
                    A decentralized platform
                    <br />
                    <span className={styles.textGradient}>for the web3 community</span>
                  </span>
                  {/* <p className={styles.containerContentLeft_text}>
                    Over 17,000 people use Entre everyday...
                  </p> */}
                  <div className={styles.containerContentLeft_buttons}>
                    <button type="button" onClick={() => {}}>
                      <a href="https://entre.link/discord" target="_blank" rel="noopener noreferrer">
                        Join our Discord
                        {' '}
                        to get all the updates
                      </a>
                      <a href="https://entre.link/discord" target="_blank" rel="noopener noreferrer">
                        <ArrowRightIcon />
                      </a>
                    </button>
                    <button type="button" onClick={() => {}}>
                      <a href="https://entre.link/help/" target="_blank" rel="noopener noreferrer">
                        Visit our FAQs
                        {' '}
                        and Help Center here
                      </a>
                      <a href="https://entre.link/help/" target="_blank" rel="noopener noreferrer">
                        <ArrowRightIcon />
                      </a>
                    </button>
                  </div>
                  <div className={styles.containerContentLeft_button}>
                    <p>
                      Or reach out to learn more.
                    </p>
                    <span>
                      <a href="mailto:support@joinentre.com" target="_blank" rel="noopener noreferrer">
                        Contact Us.
                      </a>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section id="feature" className={styles.platform}>
          <div className={styles.container}>

            <div className={styles.sectionFive}>
              <h1 className={styles.title}>Features that set us apart</h1>
              <div className={styles.textBlocks}>
                <div className={styles.featureItem}>
                  <div className={styles.featureIcon}>
                    <div className={styles.featureInnerIcon}>
                      <GlobeIcon />
                    </div>
                  </div>
                  <div className={styles.featureText}>
                    <h1>AI Introductions and Matchmaking</h1>
                    <p>
                      Our platform helps you automatically connect
                      {' '}
                      with high-value individuals who actively support each other.
                    </p>
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIcon}>
                    <div className={styles.featureInnerIcon}>
                      <RoomsIcon />
                    </div>
                  </div>
                  <div className={styles.featureText}>
                    <h1>Host Livestreams, Meetings &amp; Events</h1>
                    <p>
                      Go live or schedule events and rooms virtually.
                      {' '}
                      Charge tickets, use video or audio, record the event,
                      {' '}
                      and use screen-sharing.
                    </p>
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIcon}>
                    <div className={styles.featureInnerIcon}>
                      <MarketIcon />
                    </div>
                  </div>
                  <div className={styles.featureText}>
                    <h1>Opportunity Marketplace</h1>
                    <p>
                      Scroll through an endless supply of jobs,
                      {' '}
                      contract work, and even part-time gigs
                      {' '}
                      to find new career opportunities.
                    </p>
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIcon}>
                    <div className={styles.featureInnerIcon}>
                      <DiamondIcon />
                    </div>
                  </div>
                  <div className={styles.featureText}>
                    <h1>Exclusive Deals &amp; Discounts</h1>
                    <p>
                      Save money instantly with over $100,000 of discounts
                      {' '}
                      on your favorite tools, platforms and memberships.
                    </p>
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIcon}>
                    <div className={styles.featureInnerIcon}>
                      <ChatIcon />
                    </div>
                  </div>
                  <div className={styles.featureText}>
                    <h1>Direct &amp; Group Messaging</h1>
                    <p>
                      Keep your networking activities in one place
                      {' '}
                      by messaging other members directly
                      {' '}
                      via Entre's mobile & web apps.
                    </p>
                  </div>
                </div>
                <div className={styles.featureItem}>
                  <div className={styles.featureIcon}>
                    <div className={styles.featureInnerIcon}>
                      <CryptoIcon />
                    </div>
                  </div>
                  <div className={styles.featureText}>
                    <h1>Earn Rewards for being active</h1>
                    <p>
                      Earn Entre Points for inviting new
                      people, creating content, and
                      completing tasks within the app. In
                      the future, you can redeem Points for
                      prizes, cash, or crypto!
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section id="feature" className={styles.gradFeature}>
          <div className={styles.container}>

            <div className={styles.gradDiv}>
              <div className={styles.text}>Welcome to the Future of Networking.</div>
              <div className={styles.action}>
                <button type="button" className={styles.signin} onClick={() => topFunction()}>Sign in</button>
                <span>or</span>
                <button type="button" className={styles.signup} onClick={() => topFunction()}>Create your account</button>
              </div>
            </div>

          </div>
        </section>
      </div>
    </>
  );
};
export default NewLanding;
