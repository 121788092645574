/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable no-alert, no-console, linebreak-style */

import React from 'react';
import Typical from 'react-typical';
import styles from './AsideSection.module.scss';

const AsideSection = () => {
  // eslint-disable-next-line no-unused-vars
  const TypicalText = ({ text, duration }) => {
    const steps = ['', 500, text[0], duration, '', 500, text[1], duration];
    for (let i = 1; i < text.length; i++) {
      steps.push(text[i], duration);
    }
    steps.push(text, 2000);
    return (
      <>
        <Typical steps={steps} loop={Infinity} wrapper="p" />
      </>
    );
  };

  return (
    <div className={styles.AsideSection}>
      {/* <div className={styles.logo}>
        <img src="/logo_dark.png" alt="Logo" />
      </div> */}
      <h1 className={styles.heading}>
        The
        <div>
          <span className={styles.textGradient}> web3 </span>
        </div>
        {' '}
        professional network
      </h1>
      <div className={styles.HomeImage}>
        <img src="/images/landing/aside.png" alt="Entre Logo" />
      </div>
      <div className={styles.links}>
        <a
          className={styles.center}
          href="https://apps.apple.com/us/app/entre-entrepreneur-network/id1486408018"
          rel="noreferrer"
        >
          <img
            className={styles.links__btn}
            src="/images/landing/aivalable-on-the-app-store-2.png"
            alt="Entre Logo"
          />
        </a>
        <a
          className={styles.center}
          href="https://play.google.com/store/apps/details?id=com.joinentre&hl=es_419&gl=US"
          rel="noreferrer"
        >
          <img
            className={styles.links__btn}
            src="/images/landing/google-play-download-android-app.png"
            alt="Entre Logo"
          />
        </a>
      </div>
    </div>
  );
};

export default AsideSection;
