/* eslint-disable no-alert, no-console, linebreak-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import Link from 'next/link';
import styles from './Footer.module.scss';
// import FacebookIcon from '../../../public/icons/svg/facebook-icon.svg';
import TwitterIcon from '../../../public/icons/svg/twitter-icon.svg';
import InstagramIcon from '../../../public/icons/svg/instagram.svg';
import TiktokIcon from '../../../public/icons/svg/tiktok.svg';
import YoutubeIcon from '../../../public/icons/svg/youtube.svg';

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.media}>
        <div className={styles.mediaButtons}>
          <a target="_blank" href="https://twitter.com/joinentre" rel="noreferrer">
            <button
              type="button"
              aria-label="Twitter Icon"
              className={styles.socialMediaLinks}
            >
              <TwitterIcon />
            </button>
          </a>
          <a target="_blank" href="https://www.instagram.com/joinentre/" rel="noreferrer">
            <button
              type="button"
              aria-label="Instagram Icon"
              className={styles.socialMediaLinks}
            >
              <InstagramIcon />
            </button>
          </a>
          <a target="_blank" href="https://www.tiktok.com/@joinentre" rel="noreferrer">
            <button
              type="button"
              aria-label="Tiktok Icon"
              className={styles.socialMediaLinks}
            >
              <TiktokIcon />
            </button>
          </a>
          <a target="_blank" href="https://www.youtube.com/c/EntreTV" rel="noreferrer">
            <button
              type="button"
              aria-label="Youtube Icon"
              className={styles.socialMediaLinks}
            >
              <YoutubeIcon />
            </button>
          </a>
        </div>
        <img className={styles.logo} src="/logo_dark.png" alt="Logo" />
        {/* <div className={styles.links}>
        <Link href="#"> Explore </Link>
        <Link href="#"> Blog </Link>
        <Link href="#"> Whitepaper </Link>
        <Link href="#"> Our Company </Link>
        <Link href="#"> Changelog </Link>
      </div> */}
      </div>
    </div>
  </div>
);

export default Footer;
