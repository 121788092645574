import styles from './PublicNavBar.module.scss';
import EntreLogo from '../../blog/UI/EntreLogo/EntreLogo';
import BlogNavBar from './BlogNavBar/BlogNavBar';

const PublicNavBar = () => (
  <header className={styles.header}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <EntreLogo />
        </div>
        <BlogNavBar />
      </div>
    </div>
  </header>
);

export default PublicNavBar;
