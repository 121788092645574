/* eslint-disable react/button-has-type */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import classes from './button.module.scss';

/**
 * Primary UI component for user interaction
 */
const Button = ({
  isDisabled,
  action,
  children,
  variant,
  size,
  type,
  fullWidth,
  medium,
  background,
  shorter,
  tiny,
  shortcuts,
  small,
  borderRadius,
  customStyles,
}) => {
  // const variantClass = variant === 'primary' ? classes.primary : classes.disabled;
  const styles = clsx(classes.button, classes[variant], classes[size], {
    [classes.fullWidth]: fullWidth,
    [classes.medium]: medium,
    [classes.shorter]: shorter,
    [classes.tiny]: tiny,
    [classes.small]: small,
    [classes.shortcuts]: shortcuts,
    [classes.disabled]: isDisabled,
    [classes.borderRadius]: borderRadius,
  });
  return (
    <button
      type={type || 'button'}
      onClick={action}
      disabled={isDisabled}
      style={{ background, ...customStyles }}
      className={styles}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  isDisabled: PropTypes.bool,
  action: PropTypes.func,
  children: PropTypes.any.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  shorter: PropTypes.bool,
  tiny: PropTypes.bool,
  shortcuts: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  background: PropTypes.string,
  borderRadius: PropTypes.bool,
  customStyles: PropTypes.object,
};

Button.defaultProps = {
  isDisabled: false,
  action: undefined,
  variant: 'primary',
  size: 'normal',
  fullWidth: false,
  shorter: false,
  tiny: false,
  shortcuts: false,
  small: false,
  medium: false,
  background: null,
  type: 'button',
  borderRadius: false,
  customStyles: null,
};
export default Button;
