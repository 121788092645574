/* eslint-disable no-alert, no-console, linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = ({ size }) => (
  <span className={clsx({
    [styles.spinner]: true,
    [styles.spinner_small]: size === 'small',
    [styles.spinner_medium]: size === 'medium',
    [styles.spinner_big]: size === 'big',
  })}
  />
);

LoadingSpinner.propTypes = {
  size: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  size: null,
};

export default LoadingSpinner;
