import Image from 'next/image';
import { useSelector } from 'react-redux';
import styles from './EntreLogo.module.scss';
// import { LogoJsonLd } from "next-seo";

const EntreLogo = () => {
  const lightMode = useSelector((state) => state.main.lightMode);
  const token = useSelector((state) => state.auth.token);

  return (
    <div className={styles.container}>
      {lightMode && token ? (
        <Image
          src="/logo.png"
          alt="entre logo"
          width={108}
          height={30}
        />
      ) : (
        <Image
          src="/logo_dark.png"
          alt="entre logo"
          width={108}
          height={30}
        />
      )}
      {/* <LogoJsonLd logo={logo.src} url="https://joinentre.com" /> */}
    </div>
  );
};

export default EntreLogo;
