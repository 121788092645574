/* eslint-disable no-alert, no-console, linebreak-style */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthLayout.module.scss';
import Footer from '../../../Footer/Footer';
import AsideSection from '../AsideSection/AsideSection';
import NewLanding from '../../../../landing/NewLanding';
import PublicNavBar from '../../../NavBars/PublicNavBar/PublicNavBar';

const AuthLayout = ({ children }) => (
  <div>
    <PublicNavBar />
    <div className={styles.ContentWrapper}>
      <div className={styles.left}>
        <AsideSection />
      </div>
      <div className={styles.right}>{children}</div>
    </div>
    <div className={styles.Content}>
      <NewLanding />
    </div>
    <Footer />
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthLayout;
