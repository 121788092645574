/* eslint-disable no-alert, no-console, linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Backdrop.module.scss';

const Backdrop = ({
  closeModal, showBackdrop, disableOutsideClick, zIndex,
}) => showBackdrop && (
<div
  style={{ zIndex }}
  role="button"
  tabIndex={0}
  aria-label="Backdrop"
  onKeyDown={!disableOutsideClick ? closeModal : null}
  onClick={() => {
    if (!disableOutsideClick) { closeModal(); }
  }}
  className={`${styles.backdrop}${showBackdrop ? ` ${styles.show}` : ''}`}
/>
);

Backdrop.defaultProps = {
  closeModal: () => {},
  showBackdrop: false,
  disableOutsideClick: false,
  zIndex: 200,
};

Backdrop.propTypes = {
  closeModal: PropTypes.func,
  showBackdrop: PropTypes.bool,
  disableOutsideClick: PropTypes.bool,
  zIndex: PropTypes.number,

};

export default Backdrop;
