import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const Button = ({
  small, type, children, onclick,
}) => (
  <button
    type="button"
    className={clsx(styles.button,
      small && [styles.small],
      type === 'secondary-outline' && [styles['button--secondary-outline']])}
    onClick={onclick}
  >
    { children}
  </button>
);
export default Button;

Button.defaultProps = {
  small: false,
  type: 'primary',
  onclick: () => {},
};

Button.propTypes = {
  small: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary-outline']),
  children: PropTypes.any.isRequired,
  onclick: PropTypes.func,
};
