/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import styles from './Modal.module.scss';
import ReactPortal from './ReactPortal';
import Backdrop from '../Backdrop/Backdrop';

const Modal = ({
  children,
  isOpen,
  maximumWidth,
  isFullHeight,
  isBg,
  topOffSet,
  isAddHost,
  onClose,
  disableOutsideClick,
}) => {
  const videoModalDismissed = useSelector((state) => state.main.videoModalDismissed);
  const appModalDismissed = useSelector((state) => state.main.appModalDismissed);
  const [zIndex, setZindex] = useState(200);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('disable-scroll');
    }
    if (appModalDismissed === false) {
      if (videoModalDismissed) {
        setZindex(200);
      } else {
        setZindex(1000);
      }
    } else {
      setZindex(200);
    }
    return () => document.body.classList.remove('disable-scroll');
  }, [isOpen, appModalDismissed, videoModalDismissed]);

  return (
    isOpen && (
      <ReactPortal
        wrapperId="react-portal-modal-container"
      >
        <Backdrop
          showBackdrop={isOpen}
          closeModal={onClose}
          disableOutsideClick={disableOutsideClick}
          zIndex={zIndex}
        />
        <div
          style={{ maxWidth: maximumWidth, top: topOffSet }}
          className={clsx(
            styles.modal,
            isOpen && styles.open,
            isFullHeight && styles.isFullHeight,
            isBg && styles.isBg,
            isAddHost && styles.hostOpen,
          )}
        >
          {isOpen ? children : null}
        </div>
      </ReactPortal>
    )
  );
};

Modal.defaultProps = {
  children: null,
  isOpen: false,
  isBg: false,
  maximumWidth: '0',
  topOffSet: '10%',
  isFullHeight: false,
  isAddHost: false,
  onClose: undefined,
  disableOutsideClick: false,
};

Modal.propTypes = {
  children: PropTypes.element,
  isOpen: PropTypes.bool,
  maximumWidth: PropTypes.string,
  topOffSet: PropTypes.string,
  isFullHeight: PropTypes.bool,
  isBg: PropTypes.bool,
  isAddHost: PropTypes.bool,
  onClose: PropTypes.func,
  disableOutsideClick: PropTypes.bool,
};

export default Modal;
