import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useRouter } from 'next/router';
import styles from './BlogNavBar.module.scss';
import HeaderLink from '../../../blog/UI/HeaderLink/HeaderLink';
import Button from '../../../blog/UI/Button/Button';
import Modal from '../../../UI/Modal/Modal';
import NewAuth from '../../../Onboardings/Auth/NewAuth/NewAuth';
import EntreLogo from '../../../blog/UI/EntreLogo/EntreLogo';
import IconCancel from '../../../../../public/icons/blog/cancel.svg';
import IconHamburger from '../../../../../public/icons/blog/hamburger.svg';

const navLinks = [
  { title: 'Home', to: '/' },
  { title: 'Blog', to: '/blog' },
  { title: 'Entre PRO', to: '/pro' },
  { title: 'Help Center', to: 'https://entre.link/help', isExternalLink: true },
];

const MobileNav = ({ open, closeFunc, toggleNewAuth }) => {
  const router = useRouter();

  return (
    <div className={clsx(styles.mobileNav, open && [styles.open])}>
      <div className={clsx(styles.mobileNavHeader)}>
        <EntreLogo />
        <button
          type="button"
          className={styles['toggle-mobile-nav-btn']}
          onClick={() => closeFunc()}
        >
          <IconCancel />
        </button>
      </div>

      <Button onclick={toggleNewAuth}>Login / Sign Up</Button>
      <ul className={styles.mobileActionsList}>
        {navLinks.map((link) => (
          <li key={link.title} className={styles.mobileActionItem}>
            <HeaderLink
              mobile
              active={link.to === router.pathname}
              href={link.to}
              isExternalLink={link.isExternalLink}
            >
              {link.title}
            </HeaderLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

MobileNav.propTypes = {
  open: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  toggleNewAuth: PropTypes.func.isRequired,
};

const HeaderNav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [showNewAuth, setShowNewAuth] = useState(false);
  const router = useRouter();

  const toggleMobileNav = () => setMobileNavOpen((old) => !old);
  const toggleNewAuth = () => setShowNewAuth((old) => !old);

  return (
    <>
      <nav>
        <button type="button" onClick={toggleMobileNav} className={styles['toggle-mobile-nav-btn']}>
          <IconHamburger />
        </button>
        <MobileNav open={mobileNavOpen} closeFunc={toggleMobileNav} toggleNewAuth={toggleNewAuth} />
      </nav>

      <nav className={styles.actions}>
        <ul className={styles.actionsList}>
          {navLinks.map((link) => (
            <li key={link.title} className={styles.actionItem}>
              <HeaderLink
                active={link.to === router.pathname}
                href={link.to}
                isExternalLink={link.isExternalLink}
                isHelp={link.title === 'Help Center'}
              >
                {link.title}
              </HeaderLink>
            </li>
          ))}
        </ul>
        <Button small onclick={toggleNewAuth}> Login / Sign Up </Button>
      </nav>

      <Modal
        isOpen={showNewAuth}
        onClose={toggleNewAuth}
        topOffSet="20%"
        maximumWidth="28rem"
      >
        <NewAuth closeModal={toggleNewAuth} redirect={router.pathname === '/blog' ? 'blog' : 'feed'} />
      </Modal>
    </>
  );
};

export default HeaderNav;
